import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import KenraLanding from 'components/KenraLanding';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function KenraLandingPage(props) {
    let {
        data: { contentfulKenraLanding },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulKenraLanding,
                    'seoListing.description.description'
                )}
                title={get(contentfulKenraLanding, 'seoListing.seoTitle')}
                slug={'kenra'}
            />
            <KenraLanding page={contentfulKenraLanding} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulKenraLanding(node_locale: { eq: $locale }) {
            id
            image1 {
                localFile {
                    publicURL
                }
            }
            heroSlider {
                ...SlideFields
            }
            image1Text {
                image1Text
            }
            image1Title
            image1Url
            image3Text {
                image3Text
            }
            image3Title
            image3Url
            image3 {
                localFile {
                    publicURL
                }
            }
            image2Text {
                image2Text
            }
            image2Title
            image2Url
            image2 {
                localFile {
                    publicURL
                }
            }
            title
            text {
                raw
            }
            stylistFavorites {
                handle
            }
            stylistFavoritesViewAllLink
            latestTutorials {
                ...ContentfulVideoFields
            }
            node_locale
            seoListing {
                ...ContentfulSeoListingFields
            }
        }
    }
`;
