import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import ProductSlider from 'blocks/ProductSlider';
import HeroSlider from 'components/HeroSlider';
import urls from 'helpers/url';
import { multiline } from 'helpers/text';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import { injectIntl } from 'gatsby-plugin-react-intl';
import CustomLink from 'components/Link';

import {
    Spacing,
    SharedStyles,
    Container,
    ImageTextGrid,
    VideoRow,
} from '../kenra-storybook/index';

const {
    StSectionInfo,
    StTitle,
    StLinkMore,
    StSectionMobileViewMore,
    StSectionTitle,
    StLink,
} = SharedStyles;

const KenraLanding = ({ intl, page }) => {
    let { latestTutorials } = page;

    return (
        <>
            <Spacing removeSpaceTop>
                <HeroSlider slides={page.heroSlider} />
            </Spacing>

            <Spacing removeSpaceBottomMobile>
                <StSectionInfo useMobileBg>
                    <Container>
                        <StTitle>
                            <h2>{page.title}</h2>
                        </StTitle>
                        {documentToReactComponents(JSON.parse(page.text.raw || '{}'))}
                        <StLinkMore styleBig>
                            <CustomLink href="/collection/kenra">
                                {intl.formatMessage({ id: 'product-view-all' })}
                            </CustomLink>
                        </StLinkMore>
                    </Container>
                </StSectionInfo>
            </Spacing>

            <Spacing>
                <ImageTextGrid
                    items={[
                        {
                            img: get(page, 'image1.localFile.publicURL'),
                            title: page.image1Title,
                            text: get(page, 'image1Text.image1Text'),
                            linkText: intl.formatMessage({
                                id: 'product-view-all',
                            }),
                            linkHref: page.image1Url,
                            linkStyleWhite: true,
                        },
                        {
                            img: get(page, 'image2.localFile.publicURL'),
                            title: page.image2Title,
                            text: get(page, 'image2Text.image2Text'),
                            linkText: intl.formatMessage({
                                id: 'product-view-all',
                            }),
                            linkHref: page.image2Url,
                            linkStyleWhite: true,
                        },
                        {
                            img: get(page, 'image3.localFile.publicURL'),
                            title: page.image3Title,
                            text: get(page, 'image3Text.image3Text'),
                            linkText: intl.formatMessage({
                                id: 'product-view-all',
                            }),
                            linkHref: page.image3Url,
                            linkStyleWhite: true,
                        },
                    ]}
                />
            </Spacing>

            <Spacing>
                <ProductSlider
                    title={intl.formatMessage({
                        id: 'kenra-stylist-favorites-title',
                    })}
                    collectionHandle={get(page, 'stylistFavorites.handle')}
                    bigTitle
                    viewAllLink={get(page, 'stylistFavoritesViewAllLink')}
                />
            </Spacing>

            {latestTutorials && (
                <Spacing>
                    <StSectionMobileViewMore>
                        <StSectionTitle>
                            <Container>
                                <h2>
                                    {intl.formatMessage({
                                        id: 'kenra-latest-tutorials',
                                    })}
                                </h2>
                                <StLink>
                                    <CustomLink href={urls.videos}>
                                        {`${intl.formatMessage({
                                            id: 'view-all-text',
                                        })} (${latestTutorials.length})`}
                                    </CustomLink>
                                </StLink>
                            </Container>
                        </StSectionTitle>
                        <Container>
                            <VideoRow
                                items={latestTutorials.map(video => {
                                    let youTubeId = getYouTubeId(
                                        video.youtubeLink
                                    );
                                    return {
                                        title: multiline(
                                            get(video, 'title.title')
                                        ),
                                        text: get(video, 'description'),
                                        btnSizeMedium: true,
                                        bgImg: getPreviewUrl(youTubeId),
                                        videoType: 'youtube',
                                        autoPlay: true,
                                        videoOptions: [
                                            { src: getEmbedUrl(youTubeId) },
                                        ],
                                    };
                                })}
                            />
                        </Container>
                    </StSectionMobileViewMore>
                </Spacing>
            )}
        </>
    );
};

export default injectIntl(KenraLanding);
